html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
.top-15 {
  margin-top: 18% !important;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none !important;
  color: #0b093b;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #fff inset;
  box-shadow: transparent;
  -webkit-text-fill-color: #fff;
}
body {
  background: #fff;
  color: #0b093b;
  font-family: "Khula", sans-serif;
  overflow-x: hidden;
  font-size: 16px;
  font-weight: 400;
}
.container {
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
  padding: 0px 30px;
}


.image-animation {
  animation: fadeInUp 2s ease-out forwards;
  opacity: 0;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


.text-animation {
  display: inline-block;
  width: 0;
  overflow: hidden;
  white-space: nowrap;
  border-right: 4px solid #fff; 
  animation: typing 1s steps(30) 1s forwards, blink-caret 0.75s step-end infinite;
  opacity: 0;
  animation-delay: 0.5s; 
}

@keyframes typing {
  0% {
    width: 0;
    opacity: 1;
  }
  100% {
    width: 100%;
    opacity: 1;
  }
}





.paragraph-animation {
  animation: fadeIn 1.5s ease-out forwards;
  opacity: 0;
  animation-delay: 2s; 
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@media only screen and (max-width: 859px) {
  .container {
    padding: 0 15px;
  }
}
.regular {
  font-weight: 400 !important;
}

.extraBold {
  font-weight: 800 !important;
}
html, body {
  height: 100%;
}

.carousel,
.carousel-item,
.active {
  height: 100%;
}

.carousel-inner {
height: 100%;

}

.carousel-caption {
padding-bottom: 80px;
}

h2 {
font-size: 60px;
}

p {
padding: 10px;
}


.fill {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  opacity: 0.6;
}


.btn-transparent {
background: transparent;
color: #fff;
border: 2px solid #fff;
}
.btn-transparent:hover {
background-color: #fff;
}

.btn-rounded {
border-radius: 70px;
}

.btn-large {
padding: 11px 45px;
font-size: 18px;
}


.animated {
-webkit-animation-duration: 1.5s;
animation-duration: 1.5s;
}

@-webkit-keyframes fadeInRight {
from {
  opacity: 0;
  -webkit-transform: translate3d(100px, 0, 0);
  transform: translate3d(100px, 0, 0);
}
to {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}
}

@keyframes fadeInRight {
from {
  opacity: 0;
  -webkit-transform: translate3d(100px, 0, 0);
  transform: translate3d(100px, 0, 0);
}
to {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
-webkit-transition: transform 1s ease-in-out;
transition: transform 1s ease-in-out;
}


.carousel-item-next, .carousel-item-prev, .carousel-item.active {
animation: fadeInRight 1s forwards;
}


.carousel-control-prev-icon, .carousel-control-next-icon {
background-color: #000;
border-radius: 50%;
padding: 10px;
}

.carousel-control-prev, .carousel-control-next {
width: 5%;
}

@media (max-width: 991px) {

#home {
  padding-top: 30px;
  padding-bottom: 30px;
}

.carousel-item img {
  height: auto;
}
}


.textCenter {
  text-align: center;
}
.animate {
  transition: 0.3s ease;
}
.pointer {
  cursor: pointer;
}
.relative {
  position: relative;
}
.textCenter {
  text-align: center;
}
.textLeft {
  text-align: left;
}
.textRight {
  text-align: right;
}
.x100 {
  width: 100%;
}
.active {
  border-bottom: 2px solid #7620ff;
  color: #7620ff;
}
.tag {
  padding: 5px 10px;
  margin: 10px 0;
}
.shadow {
  box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
  -webkit-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
  -moz-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
}

.flex {
  display: flex;
}
.flexSpaceCenter {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.flexNullCenter {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.flexColumn {
  flex-direction: column;
}
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.flexSpaceNull {
  display: flex;
  justify-content: space-between;
}


.font11 {
  font-size: 0.688rem;
}
.font12 {
  font-size: 0.75rem;
}
.font13 {
  font-size: 0.813rem;
}
.font14 {
  font-size: 0.875rem;
}
.font15 {
  font-size: 0.938rem;
}
.font18 {
  font-size: 1.125rem;
}
.font20 {
  font-size: 1.25rem;
}
.font25 {
  font-size: 1.563rem;
}
.font30 {
  font-size: 1.875rem;
}
.font40 {
  font-size: 2.5rem;
}
.font60 {
  font-size: 3.75rem;
  line-height: 4.5rem;
}
@media only screen and (max-width: 859px) {
  .font11 {
    font-size: 0.688rem;
  }
  .font12 {
    font-size: 0.688rem;
  }
  .font13 {
    font-size: 0.75rem;
  }
  .font14 {
    font-size: 0.813rem;
  }
  .font15 {
    font-size: 0.813rem;
  }
  .font18 {
    font-size: 0.938rem;
  }
  .font20 {
    font-size: 1.125rem;
  }
  .font25 {
    font-size: 1.25rem;
  }
  .font30 {
    font-size: 1.5rem;
  }
  .font40 {
    font-size: 1.875rem;
  }
  .font60 {
    font-size: 2.8rem;
  }
}
/* BORDER RADIUS */
.radius8 {
  border-radius: 1rem;
}
.radius6 {
  border-radius: 0.6rem;
}
/* COLORS */
.darkColor {
  color: #0b093b;
}
.purpleColor {
  color: #7620ff;
}
.orangeColor {
  color: #f2b300;
}
.lightColor {
  color: #f5f5f5;
}
.coralColor {
  color: #c4ffff;
}
.greenColor {
  color: #49cb86;
}
.greyColor {
  color: #707070;
}
.whiteColor {
  color: #fff;
}
/* BACKGROUNDS */
.darkBg {

  background-color: #fff;
 box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.purpleBg {
  background-color: #7620ff;
}
.orangeBg {
  background-color: #f2b300;
}
.lightBg {
  background-color: #f5f5f5;
}
.coralBg {
  background-color: #c4ffff;
}
.greenBg {
  background-color: #49cb86;
}
.greyBg {
  background-color: #707070;
}
.whiteBg {
  background-color: #fff;
}
.navBG {
 

  border-bottom: 1px solid rgba(28, 2, 2, 0.058);
}
.lightbg {
  border-radius: 14px;
}

.semiBold {
  transition: all 0.3s ease-in-out;
}

.semiBold:hover {
  border-bottom: 1px solid #7620ff;
  
  
  z-index: 1;
}

.bg-beige {
  background-color: #f5f5dc;
}
.custom-container {
  max-width: 1500px;
}


.custom-card {
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.custom-card:hover {
  transform: translateY(-10px) scale(1.05);  /* Slight lift and scale effect */
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15); /* Stronger shadow */
}


.custom-icon-container {
  text-align: center;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.custom-card:hover .custom-icon-container {
  transform: translateY(-5px); 
}


.custom-icon-container .fa {
  color: #007bff; /* Set icon color */
  transition: color 0.3s ease, transform 0.3s ease;
}


.custom-card:hover .fa {
  color: #ff5722; /* Change icon color on hover */
  transform: rotate(15deg); /* Add slight rotation on hover */
}

.btn-theme{
  border: 1px solid #7620ff !important;
 
  color: white!important;
  background-color:#7620ff !important;
}

.custom-card-title {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 15px;
  transition: color 0.3s ease;
}

.custom-card-text {
  font-size: 1rem;
  color: #555;
  text-align: center;
}
.meeting-title{
  font-size: 1.5rem;
  font-weight: 600;
}

.custom-card:hover .custom-card-title {
  color: #7620ff; 
}

.custom-card:hover .custom-card-text {
  color: #777; 
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .custom-card {
    margin-bottom: 20px;
  }
}
.testimonial-slider-wrapper {
  overflow: hidden; 
  position: relative;
}

.testimonial-slider {
  display: flex;
  animation: slideRightToLeft 10s infinite linear;
}

.testimonial-card {
  flex: 0 0 auto;
  width: 250px; 
  
  margin-right: 30px; 
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.testimonial-logo .img-fluid {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.testimonial-description p {
  font-style: italic;
  font-size: 14px;
  color: #555;
}

.testimonial-footer .company-name {
  font-weight: bold;
  font-size: 16px;
}

.testimonial-footer .client-name {
  color: #888;
  font-size: 14px;
}


@keyframes slideRightToLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

footer {
  position: relative;
  z-index: 1;
}

footer .curve {
  content: '';
  position: absolute;
  top: -50px; 
  left: 0;
  width: 100%;
  height: 65px; 
  background-color: #ffffff; 
  border-radius: 0 0 50% 50%; 
  z-index: -1;
}



.animate-card {
  animation: slideIn 1s ease-out;
}

@keyframes slideIn {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}


.checkmark-container {
  margin: 20px auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #28a745;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.checkmark {
  width: 35px;
  height: 70px;
  border: solid #fff;
  border-width: 0 8px 8px 0;
  transform: rotate(45deg);
  animation: drawCheckmark 0.5s ease-in-out 0.5s forwards;
  opacity: 0;
}

@keyframes drawCheckmark {
  from {
    opacity: 0;
    transform: scale(0.8) rotate(45deg);
  }
  to {
    opacity: 1;
    transform: scale(1) rotate(45deg);
  }
}

/* Text animation */
.animate-text {
  opacity: 0;
  animation: fadeInText 1.5s ease-in-out forwards;
}

@keyframes fadeInText {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Button animation */
.animate-btn {
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.animate-btn:hover {
  transform: scale(1.1);
  background-color: #7620ff;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height of the viewport */
}
.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #6675ea;
  --_m:
    conic-gradient(#0000 10%, #000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  to {
    transform: rotate(1turn)
  }
}

